
const AboutInfo = [
    {
        bannerInfo1: "Cada miembro del equipo es fundamental para lograr la sinergia que nos caracteriza.",
        bannerInfo2: "que van mas allá de lo estrictamente laboral.",
        title: "Lorem ipsum dolor.",
        about:[
            {
                subtitle: "Una propuesta que nace en un laboratorio universitario.", 
                text: "El camino comienza con un club de jóvenes profesionales y estudiantes, que con el tiempo evolucionó y se transformó en un laboratorio de investigación y desarrollo. Luego, el laboratorio sentó las bases de lo que hoy es nuestra empresa. Este recorrido nos confiere una identidad única y nos permite contar con un semillero de formación continua. El vínculo entre la industria, la academia, el sector privado y el sector público, se combina con la investigación científica y el desarrollo tecnológico. Así, se crea un círculo virtuoso en el que continuamente mejoramos nuestras prácticas y se retroalimentan las facetas involucradas de manera constante.",
                img:"about-1.png",
                boton:"Conocer Infiniem",
                link:"https://infiniemlabs.com.ar/"
            },
            {
                subtitle: "Sentido de pertenencia e impacto genuino.", 
                text: "Nuestra experiencia y formación nos permite ofrecer una propuesta comercial especialmente enfocada en proyectos con impacto social y/o relacionados con la sustentabilidad. En Mecantronic, llevamos en nuestras raíces un fuerte espíritu emprendedor, el mismo con el que comenzamos con un club en el fondo de un aula. Este espíritu, junto con nuestra pasión por la docencia y amor por el conocimiento transformado en tecnología al servicio de la gente, nos da un gran sentido de responsabilidad social empresarial.Nuestra experiencia y formación nos permite ofrecer una propuesta comercial especialmente enfocada en proyectos con impacto social y/o relacionados con la sustentabilidad. En Mecantronic, llevamos en nuestras raíces un fuerte espíritu emprendedor, el mismo con el que comenzamos con un club en el fondo de un aula. Este espíritu, junto con nuestra pasión por la docencia y amor por el conocimiento transformado en tecnología al servicio de la gente, nos da un gran sentido de responsabilidad social empresarial.",
                img:"about-2.png"
            },
            {
                subtitle: "Para no dejar de aprender, no dejamos de enseñar.", 
                text: "Al servicio del docente para enseñar y a favor de los alumnos para potenciar sus habilidades digitales. Todo en Mecantronic está teñido de docencia. La información se comparte, las habilidades se enseñan para que la mejora individual sume a la mejora del grupo. Tal es así que contamos con una división orientada a proyectos educativos tecnológicos, donde diseñamos propuestas para todos los niveles educativos en conjunto con el estado y otras entidades privadas.",
                img:"about-3.png",
                boton:"Conocer Entender",
                link:"https://infiniemlabs.com.ar/"
            }
            
        ],
        
        strengths:[
            {
                title: "Mision",
                strength:"Lorem ipsum dolor sit amet. Ut recusandae fugit et unde exercitationem in nemo fuga."
            },    
            {
                title: "Visión",
                strength:"Lorem ipsum dolor sit amet. Ut recusandae fugit et unde exercitationem in nemo fuga."
            },    
            {
                title: "Valor",
                strength:"Lorem ipsum dolor sit amet. Ut recusandae fugit et unde exercitationem in nemo fuga."
            },
        ],
        team:[
            {
                name: "Hernán San Martín",
                job:"CEO",
                linkedin:"https://www.linkedin.com/in/hernan-dario-san-martin/",
                email:"hsanmartin@mecantronic.com.ar",
                img:"Hernán San Martín"
            },   
            {
                name: "Maximiliano Yommi",
                job:"CTO",
                linkedin:"https://www.linkedin.com/in/myommi/",
                email:"myommi@mecantronic.com.ar",
                img:"Maximiliano Yommi"
            },   
            {
                name: "Diego Lazcano",
                job:"Technical Leader",
                linkedin:"https://www.linkedin.com/in/diego-alejandro-lazcano-colodrero-19a453103/",
                email:"dlazcano@mecantronic.com.ar",
                img:"Diego Lazcano"
            },   
            {
                name: "Eduardo Sacerdoti",
                job:"Technical Leader",
                linkedin:"https://www.linkedin.com/in/esacerdoti/",
                email:"esacerdoti@mecantronic.com.ar",
                img:"Eduardo Sacerdoti"
            },
            {
                name: "Emiliano Colavitta",
                job:"Technical Leader",
                linkedin:"https://www.linkedin.com/in/emiliano-colavitta-a19ab013/",
                email:"ecolavitta@mecantronic.com.ar",
                img:"Emiliano Colavitta"
            },   
            {
                name: "Paula Ortega Riera",
                job:"PM - ML Engineer",
                linkedin:"https://www.linkedin.com/in/paulaortegariera/",
                email:"portega@mecantronic.com.ar",
                img:"Paula Ortega Riera"
            },   
            {
                name: "Nahuel Passano",
                job:"ML Engineer",
                linkedin:"https://www.linkedin.com/in/nahuel-passano-6b1652124/",
                email:"npasssano@mecantronic.com.ar",
                img:"Nahuel Passano"
            },   
            {
                name: "Francisco Roge Vallone",
                job:"ML Engineer",
                linkedin:"https://www.linkedin.com/in/francisco-roge-vallone/",
                email:"",
                img:"Francisco Roge Vallone"
            },   
            {
                name: "Santiago Steverlynck",
                job:"Design Engineer",
                linkedin:"https://www.linkedin.com/in/santiago-steverlynck-b40869234/",
                email:"",
                img:"Santiago Steverlynck"
            },   
            {
                name: "Nicolás Leunda",
                job:"Design Engineer",
                linkedin:"https://www.linkedin.com/in/nicolas-leunda-a300ba1ba/",
                email:"",
                img:"Nicolás Leunda"
            }   
        ],
        gallery:[
            {src:"1.png",
            alt:"Team Mecantronic"
            },
            {src:"2.png",
            alt:"Team Mecantronic"
            },
            {src:"3.png",
            alt:"Team Mecantronic"
            },
            {src:"4.png",
            alt:"Team Mecantronic"
            },
            {src:"5.png",
            alt:"Team Mecantronic"
            },
            {src:"6.png",
            alt:"Team Mecantronic"
            },
            {src:"7.png",
            alt:"Team Mecantronic"
            },
            {src:"8.png",
            alt:"Team Mecantronic"
            },
            {src:"9.png",
            alt:"Team Mecantronic"
            },
            {src:"10.png",
            alt:"Team Mecantronic"
            },
            {src:"11.png",
            alt:"Team Mecantronic"
            },
            {src:"12.png",
            alt:"Team Mecantronic"
            }
        ],
        teamBreakPoints :[
            {width: 1, itemsToShow: 1},
            {width: 550, itemsToShow: 2},
            {width: 768, itemsToShow: 3},
            {width: 1200, itemsToShow: 4},
            {width: 1800, itemsToShow: 5}
        ],
        galleryBreakPoints :[
            {width: 1, itemsToShow: 1},
            {width: 550, itemsToShow: 1},
            {width: 768, itemsToShow: 1},
            {width: 1200, itemsToShow: 2},
            {width: 1800, itemsToShow: 3}
        ]
    }
]
export default AboutInfo;


